import { Box, VStack, Text } from "@chakra-ui/react";
import HomeIcon from "../../components/views/HomeIcon";
import RenderBlogs from "../../components/views/blogs/RenderBlogs";

function Blogs() {
  return (
    <>
      <VStack spacing={6}>
        <HomeIcon />
        <Box>
          {" "}
          <Text
            color="green.300"
            fontSize={["xl", "2xl", "3xl", "4xl"]}
            style={{ fontFamily: "Lobster" }}
          >
            Blog
          </Text>
        </Box>

        <RenderBlogs />
      </VStack>

      <style>
        {`
        @import url('https://fonts.googleapis.com/css?family=Lobster');
      `}
      </style>
    </>
  );
}

export default Blogs;
