export const AllBlogs = [
  {
    id: 0,
    title: "You Are What You Think You Are",
    tags: ["mind", "positive thinking", "success"],
  },
  {
    id: 1,
    title: "How to Form Positive Thinking Habits",
    tags: ["mind", "positive thinking", "habits"],
  },
  {
    id: 2,
    title: "Remember Your Why",
    tags: ["motivation", "purpose", "focus"],
  },
  {
    id: 3,
    title: "It's Never Too Late to Train Your Brain for Positivity",
    tags: ["neuroscience", "positive thinking", "personal growth"],
  },
  {
    id: 4,
    title: "You Don't Have to Be Perfect",
    tags: ["self-acceptance", "imperfection", "personal growth"],
  },
];
