import { ChakraProvider, Box, HStack, theme, VStack } from "@chakra-ui/react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation,
} from "react-router-dom";
import { ColorModeSwitcher } from "./ColorModeSwitcher";
import ScrollToTop from "./components/views/ScrollToTop";
import Footer from "./components/views/Footer";
import Layout from "./components/views/Layout";
import Home from "./pages/Home.js";
import NoPage from "./pages/misc/NoPage.js";
import Blogs from "./pages/blogs/Blogs.js";
import About from "./pages/misc/About.js";
import "./App.css";
import NavBreadCrumb from "./components/views/NavBreadCrumb.js";

function AppLocation() {
  const location = useLocation();
  const isHomePage = location.pathname === "/";

  return (
    <ChakraProvider theme={theme}>
      {/* <RouteComponent justifySelf="flex-end"
      style={{
        position: 'absolute',
        top: '0%',
        left: '0%',
      }}/> */}

      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
   
  
          <Route path="/blogs" element={<Blogs />} />
  
          {/* <Route path="/faq" element={<FAQ />} /> */}
          <Route path="/about" element={<About />} />

          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>

      <Box
        style={{
          position: "absolute",
          top: "0%",
          right: "0%",
        }}
      >
        <HStack spacing={3}>
          <ColorModeSwitcher justifySelf="flex-end" />
        </HStack>
      </Box>

      <ScrollToTop />
      <VStack>
        {/* {!isGameRoute && (
          <Box mb="8px" mt="58px">
            <NavBreadCrumb />
          </Box>
        )}
        {!isGameRoute && (
          <Box mb="8px">
            <CreatorInfo />
          </Box>
        )} */}

        <Box mb="8px" mt="58px">
          <NavBreadCrumb />
        </Box>

        <Box>
          <Footer />
        </Box>
      </VStack>

      <style>
        {`
        @import url('https://fonts.googleapis.com/css?family=Lobster');
      `}
      </style>
    </ChakraProvider>
  );
}

function App() {
  return (
    <Router>
      <AppLocation />
    </Router>
  );
}

export default App;