import { useState } from "react";
import {
  Box,
  VStack,
  Text,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Image,
  Link,
  Icon,
  Wrap,
  WrapItem,
  HStack,
  Button,
} from "@chakra-ui/react";
import { ExternalLinkIcon } from "@chakra-ui/icons";
import { AiTwotoneMail } from "react-icons/ai";
import HomeIcon from "../../components/views/HomeIcon";
import AboutImg from "../../resources/images/About.png";
import CharmingNatureLogo from "../../resources/images/charming-nature-logo.png";
import { CopyToClipboard } from "react-copy-to-clipboard";

function About() {
  const ContactCard = ({ title, emailAddr }) => {
    return (
      <Box p="6" rounded="md" boxShadow="outline" mt="6">
        <HStack>
          <Icon
            as={AiTwotoneMail}
            boxSize="38px"
            color="green.300"
            aria-label="General inquiries"
          />
          <Text fontWeight="bold">{title}:</Text>
        </HStack>
        <HStack>
          <Text>{emailAddr}</Text>
          <CopyToClipboard text={emailAddr}>
            <Button size="sm" colorScheme="green" variant="outline">
              Copy
            </Button>
          </CopyToClipboard>
        </HStack>
      </Box>
    );
  };
  const RenderContact = () => {
    return (
      <Box
        maxW={["xs", "sm", "md"]}
        p={6}
        fontFamily="Helvetica Neue"
        textAlign="left"
      >
        <VStack spacing={4}>
          <Text fontSize={["lg", "xl", "2xl"]}>
            Have a question, suggestion, or just want to say hello? Drop us a
            line—we'd love to hear from you!
          </Text>
          <ContactCard
            title="General"
            emailAddr="support@charmingnature.shop"
          />

          <ContactCard
            title="Apparel"
            emailAddr="support@charmingnature.shop"
          />

          <ContactCard
            title="Home Decor"
            emailAddr="support@charmingnature.store"
          />

          <Text mt="4" fontSize={["md", "lg", "xl"]}>
            Feel free to reach out to us—we're here to help!
          </Text>
        </VStack>
      </Box>
    );
  };

  const AboutSection = () => {
    return (
      <Box p={6} border="none" borderWidth="0" maxW={["md", "lg", "xl", "2xl"]}>
        <Box
          fontSize={["md", "lg", "xl", "2xl"]}
          fontFamily="Helvetica Neue"
          textAlign="left"
        >
          <VStack spacing={6}>
            <Text>
              Welcome to Charming Nature Shop! We believe in the beauty and
              serenity that nature brings to our everyday lives. Our mission is
              to infuse your space with the essence of nature through
              thoughtfully curated products that inspire and uplift.
            </Text>

            <img
              src={CharmingNatureLogo}
              alt="Charming Nature Shop Logo"
              style={{ width: "158px", height: "auto" }}
            />

            <Text
              as="b"
              color="green.300"
              style={{ fontFamily: "Lobster" }}
              fontSize="2xl"
              mt="6"
            >
              Explore our branches
            </Text>

            <Text>
              Apparel Shop: Visit{" "}
              <Link
                color="green.300"
                href="https://apparel.charmingnature.shop/"
                isExternal
              >
                Charming Nature Apparel Shop <ExternalLinkIcon mx="2px" />
              </Link>{" "}
              for a collection that celebrates nature-inspired designs. Embrace
              comfort and style with pieces that remind you of the great
              outdoors.
            </Text>

            <Text>
              Home Decor Store: Discover unique decor pieces at{" "}
              <Link
                color="green.300"
                href="https://www.charmingnature.store/"
                isExternal
              >
                Charming Nature Store <ExternalLinkIcon mx="2px" />
              </Link>{" "}
              that bring the beauty of nature into your home. From wall art to
              accessories, our selection aims to create a calming and inviting
              atmosphere in your space.
            </Text>

            <Text>
              At Charming Nature Shop, we are committed to providing quality
              products that not only enhance your style but also connect you
              with the tranquility and charm of the natural world. Join us on
              this journey to embrace nature’s beauty in every aspect of your
              life.
            </Text>
          </VStack>
        </Box>
      </Box>
    );
  };

  return (
    <>
      <VStack spacing={6}>
        <HomeIcon />
        <img src={AboutImg} style={{ width: "318px", height: "auto" }} />
        <Tabs
          variant="soft-rounded"
          colorScheme="green"
          size="lg"
          align="center"
        >
          <TabList>
            <Tab>
              <Text fontSize={["xl", "2xl"]}>About</Text>
            </Tab>
            <Tab>
              <Text fontSize={["xl", "2xl"]}>Contact</Text>
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <AboutSection />
            </TabPanel>
            <TabPanel>
              <RenderContact />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </VStack>

      <style>
        {`
        @import url('https://fonts.googleapis.com/css?family=Lobster');
      `}
      </style>
    </>
  );
}

export default About;
