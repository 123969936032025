import {
  Link,
  Menu,
  MenuItem,
  MenuButton,
  MenuList,
  IconButton,
  Flex,
  Icon,
} from "@chakra-ui/react";
import {
  FaYinYang,
  FaStore,
  FaBookOpen,
  FaImages,
  FaInfo,
  FaTshirt
} from "react-icons/fa";

import {
  BrowserRouter as Router,
  NavLink as RouterLink, // <-- import the NavLink component
} from "react-router-dom";
import { HamburgerIcon } from "@chakra-ui/icons";
import { RiHomeHeartLine, RiMoonClearFill } from "react-icons/ri";

function NavMenu({ isGameRoute }) {
  const _style = isGameRoute
    ? {}
    : {
        position: "absolute",
        top: "0%",
        left: "0%",
      };
  const RenderMenuItem = ({ _icon, _to, _title, _color }) => {
    return (
      <MenuItem>
        <Link
          as={RouterLink}
          to={_to}
          p={2}
          _activeLink={{ fontWeight: "bold" }}
        >
          <Flex
            justifyContent="flex-start"
            ml={4}
            fontSize="1.25rem"
            alignItems="center"
          >
            <Icon as={_icon} mr={2} color={_color} />
            {_title}
          </Flex>
        </Link>
      </MenuItem>
    );
  };
  return (
    <div style={_style}>
      <Menu>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<HamburgerIcon w={7} h={7} />}
          variant="outline"
        ></MenuButton>
        <MenuList>
          <RenderMenuItem
            _icon={RiHomeHeartLine}
            _to="/"
            _title="Home"
            _color="green.300"
          />
          {/* <RenderMenuItem
            _icon={FaTshirt}
            _to="/style/apparel"
            _title="Apparel"
            _color="pink.200"
          />
            <RenderMenuItem
            _icon={FaImages}
            _to="/style/wallart"
            _title="Wall Art"
            _color="green.300"
          /> */}
          <RenderMenuItem
            _icon={FaInfo}
            _to="/about"
            _title="About"
            _color="blue.300"
          />
{/* 
          <RenderMenuItem
            _icon={FaBookOpen}
            _to="/blogs"
            _title="Blog"
            _color="green.300"
          /> */}

          {/* 
          <RenderMenuItem _icon={FaUser} _to="/user" _title="User" />
          <RenderMenuItem
            _icon={FaShieldAlt}
            _to="/membership"
            _title="Membership"
          /> */}
          {/* <RenderMenuItem
            _icon={FaQuestionCircle}
            _to="/faq"
            _title="FAQ"
            _color="green.300"
          /> */}
          {/* <RenderMenuItem
            _icon={FaFileContract}
            _to="/termsandconditions"
            _title="Terms and Conditions"
          /> */}
        </MenuList>
      </Menu>
    </div>
  );
}

export default NavMenu;
