import { Link as ReactLink } from "react-router-dom";
import {
  Icon,
  Text,
  Link,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Box,
} from "@chakra-ui/react";
import {
  GiBeveledStar,
  GiTwirlyFlower,
  GiLotusFlower,
  GiHighGrass,
  GiButterfly,
} from "react-icons/gi";

const NavBreadCrumb = () => {
  return (
    <Box>
      <Breadcrumb
        separator={<Icon as={GiButterfly} w={6} h={6} color="green.300" />}
      >
        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/">
              <Text as="i" fontSize="sm">
                Home
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        {/* <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/style/apparel">
              <Text as="i" fontSize="sm">
                Apparel
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>
        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/style/wallart">
              <Text as="i" fontSize="sm">
                Wall Art
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem> */}
        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/about">
              <Text as="i" fontSize="sm">
                About
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem>

        {/* <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/shipping">
              <Text as="i" fontSize="sm">
                Shipping & Returns
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem> */}

 
{/* 
        <BreadcrumbItem>
          <BreadcrumbLink>
            <Link as={ReactLink} to="/blogs">
              <Text as="i" fontSize="sm">
                Blog
              </Text>
            </Link>
          </BreadcrumbLink>
        </BreadcrumbItem> */}
      </Breadcrumb>
    </Box>
  );
};

export default NavBreadCrumb;
