import {
  Box,
  Text,
  Link,
  List,
  ListItem,
  ListIcon,
  HStack,
} from "@chakra-ui/react";
import { AllBlogs } from "./articles/AllBlogs";
import { Link as ReactLink } from "react-router-dom";
import { FaBookOpen } from "react-icons/fa";
const RenderBlogs = () => {
  const getToUrl = (index) => {
    return `/blogs/${index}`;
  };
  return (
    <>
      <List spacing={3}>
        {AllBlogs.length > 0 &&
          AllBlogs.map((blog, i) => (
            <Box key={i}>
              <ListItem>
                <HStack>
                  <ListIcon as={FaBookOpen} color="green.500" />
                  <Link as={ReactLink} to={getToUrl(i)}>
                    <Text fontSize='xl'>{blog.title}</Text>
                  </Link>
                </HStack>
              </ListItem>
            </Box>
          ))}
      </List>
    </>
  );
};

export default RenderBlogs;
