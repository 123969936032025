import { Box, VStack, Text, Wrap, WrapItem, Link } from "@chakra-ui/react";

// import RenderBlogs from "../components/views/blogs/RenderBlogs";
import NatureImg from "../resources/images/leaves.png";
import HomeDecorImg from "../resources/images/phonecase-mockup.png";

function Home() {
  const shops = [
    {
      shopLink: "https://apparel.charmingnature.shop/",
      imageUrl:
        "https://res.cloudinary.com/dmtl2otiy/image/upload/v1727569307/embrace-change-bc3001-2-soft-pink_ebffmc.png", // Replace with actual image URL
      imageAlt: "Apparel Shop",
      title: "Apparel Shop",
      texts: `Embrace Nature's Charms`,
    },
    {
      shopLink: "https://www.charmingnature.store/", // Add the link for the Home Decor Store
      imageUrl: HomeDecorImg, // Replace with actual image URL
      imageAlt: "Home Decor Store",
      title: "Home Decor Store",
      texts: `Bring Nature's Charms In`,
    },
  ];

  function ShopCard({ shopLink, imageUrl, imageAlt, title, texts }) {
    return (
      <Link href={shopLink} isExternal>
        <Box maxW="sm" borderWidth="1px" borderRadius="lg">
          <VStack>
            <img
              style={{ width: "258px", height: "auto", borderRadius: '10%' }}
              src={imageUrl}
              alt={imageAlt}
            />

            <Box pl="6" pr="6">
              <Text as="b" fontSize="xl" color="green.300">
                {title}
              </Text>
            </Box>
            <Box pl="6" pr="6">
              <Text as="i" color="green.300">
                {texts}
              </Text>
            </Box>
          </VStack>
        </Box>
      </Link>
    );
  }
  return (
    <>
      <VStack spacing={6}>
        <Box mt="18px">
          <VStack spacing={3}>
            <Text
              fontSize={["xl", "2xl", "3xl", "5xl"]}
              color="green.300"
              style={{ fontFamily: "Lobster" }}
            >
              Charming Nature Shop
            </Text>

            <img
              src={NatureImg}
              alt="Brain"
              style={{ width: "258px", height: "auto" }}
            />

            <Text
              fontSize={["md", "lg", "xl"]}
              color="green.300"
              style={{ fontFamily: "Lobster" }}
            >
              Infuse Nature's Charms in Your Everyday Life
            </Text>

            <Text
              mt={6}
              fontSize={["xl", "2xl", "3xl", "5xl"]}
              color="green.300"
              style={{ fontFamily: "Lobster" }}
            >
              Explore Our Collections
            </Text>

            <Wrap spacing={6}>
              {shops.length > 0 &&
                shops.map((shop, index) => (
                  <WrapItem key={index}>
                    <ShopCard
                      shopLink={shop.shopLink}
                      imageUrl={shop.imageUrl}
                      imageAlt={shop.imageAlt}
                      title={shop.title}
                      texts={shop.texts}
                    />
                  </WrapItem>
                ))}
            </Wrap>
          </VStack>
        </Box>
        {/* <RenderBlogs /> */}
      </VStack>

      <style>
        {`
        @import url('https://fonts.googleapis.com/css?family=Lobster');
      `}
      </style>
    </>
  );
}

export default Home;
